<template>
  <v-card :loading="loading" class="main-card mb-3 card">
    <div
      :class="[this.$vuetify.theme.dark ? 'dark-bg' : '']"
      class="card-header-tab card-header"
    >
      <div
        :class="[this.$vuetify.theme.dark ? 'text-white' : '']"
        class="card-header-title card-title font-size-lg text-capitalize font-weight-normal"
      >
        <font-awesome-icon
          :icon="['fal', 'calendar-day']"
          size="2x"
          class="pr-2 daily"
        />
        {{ $t("settings.lang_dailyOverviewDashboard") }}
      </div>
    </div>

    <div class="grid-menu grid-menu-3col">
      <div class="no-gutters row">
        <div
          class="col-sm-6 col-md-6"
          :class="[
            $store.getters['permissions/checkModule'](2)
              ? 'col-lg-3 col-xl-3'
              : 'col-lg-4 col-xl-4',
          ]"
        >
          <div class="widget-chart">
            <div class="icon-wrapper rounded-circle">
              <div class="icon-wrapper-bg bg-primary"></div>
              <font-awesome-icon
                :icon="['fas', 'clock']"
                size="2x"
                class="mx-auto"
              />
            </div>
            <div class="widget-numbers">
              {{ data.timePerOrder }} (m:s)
            </div>
            <div class="widget-subheading">
              {{ $t("settings.lang_timeToFinishAnOrder") }}
            </div>
            <!-- <div :class="'widget-description ' + margins.finishedPerHour.color">
              <font-awesome-icon :icon="margins.finishedPerHour.icon" />
              <span class="pl-1">{{ margins.finishedPerHour.increase }}%</span>
            </div> -->
          </div>
        </div>

        <div
          class="col-sm-6 col-md-6"
          :class="[
            $store.getters['permissions/checkModule'](2)
              ? 'col-lg-3 col-xl-3'
              : 'col-lg-4 col-xl-4',
          ]"
        >
          <div class="widget-chart">
            <div class="icon-wrapper rounded-circle">
              <div class="icon-wrapper-bg bg-alternate"></div>
              <font-awesome-icon
                :icon="['fas', 'coins']"
                size="2x"
                class="mx-auto"
              />
            </div>
            <div class="widget-numbers">
              {{ data.finishedPerHour.toFixed(2) }} x
            </div>
            <div class="widget-subheading">
              {{ $t("settings.lang_finishedOrdersPerHour") }}
            </div>
          </div>
        </div>

        <div
          class="col-sm-6 col-md-6"
          :class="[
            $store.getters['permissions/checkModule'](2)
              ? 'col-lg-3 col-xl-3'
              : 'col-lg-4 col-xl-4',
          ]"
        >
          <div class="widget-chart">
            <div class="icon-wrapper rounded-circle">
              <div class="icon-wrapper-bg bg-warning"></div>
              <font-awesome-icon
                :icon="['fas', 'tv-alt']"
                size="2x"
                class="mx-auto"
                style="font-size: 22px"
              />
            </div>
            <div class="widget-numbers">{{ data.totalKioskOrder || 0 }} x</div>
            <div class="widget-subheading">
              {{ $t("settings.lang_ordersFromKiosks") }}
            </div>
          </div>
        </div>

        <div class="col-sm-6 col-md-6 col-lg-3 col-xl-3">
          <div class="widget-chart">
            <div class="icon-wrapper rounded-circle">
              <div class="icon-wrapper-bg bg-success"></div>
              <font-awesome-icon
                :icon="['fas', 'utensils']"
                size="2x"
                class="mx-auto"
              />
            </div>
            <div class="widget-numbers">
              {{ data.totalPosOrder || 0 }} x
            </div>
            <div class="widget-subheading">
              {{ $t("settings.lang_ordersFromPos") }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-card>
</template>

<style scoped>
* {
  z-index: 0;
}
font-awesome-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}
.daily {
  color: #794c8a !important;
}
.widget-numbers {
  font-size: 1.4rem !important;
}
@media only screen and (min-width: 900px) {
  .row.no-gutters > .col,
  .row.no-gutters > [class*="col-"] {
    border-width: 1px;
  }
}

/* .grid-menu > [class*=col-] {
        border-width: 1px;
    }

    @media only screen and (max-width: 1200px) {
        .grid-menu [class*="col-"]:nth-child(2){
            border-right: #dee2e6 solid 1px;
        }
        .grid-menu [class*="col-"]:nth-child(4){
            border-right: #dee2e6 solid 1px;
        }
    } */
</style>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCalendarDay } from "@fortawesome/pro-light-svg-icons";
import {
  faCoins,
  faClock,
  faTvAlt,
  faAngleUp,
  faAngleDown,
  faAngleRight,
  faUtensils,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
library.add(
  faCalendarDay,
  faAngleUp,
  faAngleDown,
  faCoins,
  faClock,
  faTvAlt,
  faAngleRight,
  faUtensils
);

import { ENDPOINTS } from "../../../../config";

export default {
  name: "AllKitchenmonitorDailyReportComponent.vue",
  components: {
    FontAwesomeIcon,
  },

  data() {
    return {
      ENDPOINTS,
      loading: true,
      data: {
        finishedPerHour: 0,
        timePerOrder: 0,
        totalKioskOrder: 0,
        totalPosOrder: 0,
      },
    };
  },

  mounted() {
    this.getCashiersReport();
  },

  methods: {
    async getCashiersReport() {
      this.loading = true;

      this.axios
        .get(ENDPOINTS.SETTINGS.KITCHENMONITORS.DASHBOARD.SUMMARY)
        .then((res) => {
          if (res.data.success) {
            this.data = res.data.data;
          }
        })
        .catch((err) => {
          Events.$emit("showSnackbar", {
            color: "error",
            message: err.message,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
