<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="12">
        <AllKitchenmonitorDailyReportComponent />
      </v-col>
      <v-col cols="12" sm="12" md="12" lg="5"> </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <kassen-component />
      </v-col>
    </v-row>

    <!-- <v-row>
      <v-col cols="12">
        <SalesChartNumberOfFinishedOrders />
      </v-col>
    </v-row> -->

    <!-- <v-row>
      <v-col cols="12" md="6">
        <MealFastestFinishTimeComponent />
      </v-col>
      <v-col cols="12" md="6">
        <MealLongestFinishTimeComponent />
      </v-col>
    </v-row> -->

    <v-row>
      <v-col cols="12">
        <SalesChartNumberOfProcessedTotalOrders />
      </v-col>
    </v-row>
    <!-- 
    <v-row>
      <v-col cols="12">
        <AverageTimeToFinishMealComponent />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <activities-component />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <UsersDailyReport />
      </v-col>
    </v-row> -->

    <!-- <v-row>
      <v-col cols="12">
        <CurentCashierDailyReport />
      </v-col>
    </v-row> -->
  </v-container>
</template>

<script>
import AllKitchenmonitorDailyReportComponent from "@/components/settings/kitchenmonitor/dashboard/AllKitchenmonitorDailyReportComponent";
import KassenComponent from "@/components/settings/kitchenmonitor/dashboard/KassenComponent";
import ActivitiesComponent from "@/components/settings/kitchenmonitor/dashboard/ActivitiesComponent";
import SalesChartNumberOfFinishedOrders from "@/components/settings/kitchenmonitor/dashboard/SalesChartNumberOfFinishedOrders.vue";
import SalesChartNumberOfProcessedTotalOrders from "@/components/settings/kitchenmonitor/dashboard/SalesChartNumberOfProcessedTotalOrders.vue";
import MealFastestFinishTimeComponent from "./MealFastestFinishTimeComponent.vue";
import MealLongestFinishTimeComponent from "./MealLongestFinishTimeComponent.vue";
import UsersDailyReport from "@/components/settings/kitchenmonitor/dashboard/UsersDailyReport.vue";
import AverageTimeToFinishMealComponent from "./AverageTimeToFinishMealComponent.vue";
// import CurentCashierDailyReport from "@/components/settings/kitchenmonitor/dashboard/CurentCashierDailyReport";

export default {
  components: {
    KassenComponent,
    AllKitchenmonitorDailyReportComponent,
    SalesChartNumberOfFinishedOrders,
    SalesChartNumberOfProcessedTotalOrders,
    MealFastestFinishTimeComponent,
    MealLongestFinishTimeComponent,
    ActivitiesComponent,
    UsersDailyReport,
    AverageTimeToFinishMealComponent,
    // CurentCashierDailyReport,
  },

  data: () => ({
    heading: "Herzlich Willkommen",
    subheading: "Hier sehen Sie Ihre Kasse im Überblick",
    icon: "pe-7s-home icon-gradient bg-tempting-azure",
  }),
};
</script>
