<template>
  <div>
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>
          <v-card-title>{{ $t("generic.lang_userOverview") }}</v-card-title>
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn
          :disabled="loading"
          :loading="loading"
          @click="getUsersReport"
          icon
        >
          <v-icon>refresh</v-icon>
        </v-btn>
      </v-toolbar>

      <v-divider style="margin-top: 5px"></v-divider>

      <Datatable
        ref="sales_per_hour"
        :api-endpoint="ENDPOINTS.DATATABLES.ANALYTICS.SALESPERHOUR"
        :datatable-headers="datatableHeaders"
        excel-file-name="sales-per-hours"
        :data="this.params"
        :show-select-checkbox="false"
        :show-search-field="false"
      >
      </Datatable>

      <!-- <v-data-table
        :loading="loading"
        :headers="tableHeaders"
        :items="data"
        :items-per-page="30"
        class="elevation-1"
        hide-default-footer
      >
        <template v-slot:item="{ item }">
          <tr>
            <td>{{ item.id }}</td>
            <td>{{ item.name }}</td>
            <td>{{ item.totalBrutto | currency }}</td>
            <td>{{ item.totalNetto | currency }}</td>
            <td>{{ item.salesTarget | currency }}</td>

            <td v-if="!item.salesTarget > 0"></td>
            <td v-else>
              <v-progress-linear
                style="margin-top: 10px"
                color="success"
                :value="item.salesTargetPercent"
                height="8"
              ></v-progress-linear>
              <p class="text-center" style="margin-bottom: 0">
                {{ item.salesTargetPercent }}%
              </p>
            </td>
          </tr>
        </template>
      </v-data-table> -->
    </v-card>
  </div>
</template>

<script>
import { ENDPOINTS } from "@/config";
import { mapState } from "vuex";
import moment from "moment";
import Datatable from "@/components/datatable/Datatable";

export default {
  name: "UsersDailyReport",
  components: {
    Datatable,
  },
  data() {
    return {
      ENDPOINTS,
      loading: true,
      data: [],
      date: null,
      datatableHeaders: [
        { text: "#", value: "date" },
        { text: this.$t("settings.lang_operator"), value: "day" },
        {
          text: this.$t("00"),
          value: "00",
        },
        {
          text: this.$t("01"),
          value: "01",
        },
        {
          text: this.$t("02"),
          value: "02",
        },
        {
          text: this.$t("03"),
          value: "03",
        },
        {
          text: this.$t("04"),
          value: "04",
        },
        {
          text: this.$t("05"),
          value: "05",
        },
        {
          text: this.$t("06"),
          value: "06",
        },
        {
          text: this.$t("07"),
          value: "07",
        },
        {
          text: this.$t("08"),
          value: "08",
        },
        {
          text: this.$t("09"),
          value: "09",
        },
        {
          text: this.$t("10"),
          value: "10",
        },
        {
          text: this.$t("11"),
          value: "11",
        },
        {
          text: this.$t("12"),
          value: "12",
        },
        {
          text: this.$t("13"),
          value: "13",
        },
        {
          text: this.$t("14"),
          value: "14",
        },
        {
          text: this.$t("15"),
          value: "15",
        },
        {
          text: this.$t("16"),
          value: "16",
        },
        {
          text: this.$t("17"),
          value: "17",
        },
        {
          text: this.$t("18"),
          value: "18",
        },
        {
          text: this.$t("19"),
          value: "19",
        },
        {
          text: this.$t("20"),
          value: "20",
        },
        {
          text: this.$t("21"),
          value: "21",
        },
        {
          text: this.$t("22"),
          value: "22",
        },
        {
          text: this.$t("23"),
          value: "23",
        },
      ],
    };
  },

  computed: {
    ...mapState(["api"]),
    params() {
      return this.getDate("2025-01-07");
    },
  },

  mounted() {
    this.getUsersReport();
  },

  methods: {
    getUsersReport() {
      this.loading = true;

      this.axios.post("get/dashboard/usersDailyReport/").then((res) => {
        if (res.data.success) {
          // this.data = res.data.data;
        }

        this.loading = false;
      });
    },
    getDate(date) {
      let startEndDate = new Date(date);

      return {
        start_date:
          date == "" || date == null
            ? null
            : moment
                .unix(startEndDate.getTime() / 1000)
                .startOf("days")
                .unix(),

        end_date:
          date == "" || date == null
            ? null
            : moment
                .unix(startEndDate.getTime() / 1000)
                .endOf("days")
                .unix(),
      };
    },
  },
};
</script>
