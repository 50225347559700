<template>
  <div
    :style="this.$vuetify.theme.dark ? 'background-color: #1E1E1E;' : ''"
    class="main-card mb-3 card"
  >
    <div
      :class="[this.$vuetify.theme.dark ? 'dark-bg' : '']"
      class="card-header-tab card-header"
    >
      <div
        :class="[this.$vuetify.theme.dark ? 'white--text' : '']"
        class="card-header-title card-title font-size-lg text-capitalize font-weight-normal"
      >
        {{ $t("Orders per operator per hour") }}
      </div>
    </div>
    <div class="card-body">
      <div class="scroll-area-lg" style="height: 480px;">
        <div
          class="vertical-time-icons vertical-timeline vertical-timeline--animate vertical-timeline--one-column"
          v-if="!loading"
        >
          <div
            class="vertical-timeline-element"
            v-for="(item, i) in data"
            :key="i"
          >
            <div>
              <span class="vertical-timeline-element-icon bounce-in">
                <div
                  v-if="isSupported(item)"
                  :class="'timeline-icon ' + events[item.eventType].style"
                >
                  <font-awesome-icon
                    :icon="events[item.eventType].icon"
                    :class="events[item.eventType].iconColor"
                  />
                </div>
                <div v-else class="timeline-icon border-primary">
                  <font-awesome-icon :icon="['fal', 'newspaper']" />
                </div>
              </span>
              <div class="vertical-timeline-element-content bounce-in">
                <h4 class="timeline-title">
                  {{ item.first_name + " " + item.last_name }}
                </h4>
                <!-- <p>number of the meals per hour</p> -->
                <div v-if="showTopChart">
                  <apexchart
                    height="350"
                    type="bar"
                    :options="chartOptions"
                    :series="series"
                  ></apexchart>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row justify-center" v-if="!loading && emptyData">
          <div class="col-12 text-center">
            <h3>{{ $t("dashboard.lang_noNewEvents") }}</h3>
          </div>
        </div>
        <div class="row justify-center">
          <div class="col-12" v-if="loading">
            <v-skeleton-loader
              v-for="n in 5"
              :key="n"
              height="94"
              type="list-item-avatar-three-line"
            >
            </v-skeleton-loader>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ENDPOINTS } from "@/config";
import { Events } from "@/plugins/events";
import { mapState } from "vuex";
import moment from "moment";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fal } from "@fortawesome/pro-light-svg-icons";
import { fas } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
library.add(fal, fas);
import VueApexCharts from "vue-apexcharts";

export default {
  name: "ActivitiesComponent",

  components: {
    VuePerfectScrollbar,
    FontAwesomeIcon,
    apexchart: VueApexCharts,
  },

  data() {
    return {
      loading: true,
      timer: null,
      showTopChart: false,
      series: [
        {
          name: this.$t("generic.lang_orders"),
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          stacked: true,
          toolbar: {
            show: true,
          },
        },
        theme: {
          mode: this.$vuetify.theme.dark ? "dark" : "light",
        },
        stroke: {
          curve: "smooth",
        },
        yaxis: [
          {
            title: {
              text: this.$t("processed orders"),
            },
          },
        ],
        xaxis: {
          title: {
            text: this.$t("generic.lang_hours"),
          },
          labels: {
            formatter: function (val) {
              return val + ":00h";
            },
          },
          categories: [
            "00",
            "01",
            "02",
            "03",
            "04",
            "05",
            "06",
            "07",
            "08",
            "09",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
            "20",
            "21",
            "22",
            "23",
          ],
        },
      },
      events: {
        /*
         *'eventType':
         * {
         *   style:"border-color", => the circle's color, use one of the known colors 'primary,warning etc...'
         *   icon:['fal/fas/fad/far','icons-name'],
         *   fontColor
         *
         * */
        LOGIN: {
          style: "border-success",
          icon: ["fal", "sign-in-alt"],
          iconColor: "text-info",
        },
        LOGOUT: {
          style: "border-primary",
          icon: ["fal", "sign-out-alt"],
          iconColor: "text-danger",
        },
        DELETE: {
          style: "border-primary",
          icon: ["fal", "trash-alt"],
          iconColor: "text-danger",
        },
        CASHDRAWER: {
          style: "border-info",
          icon: ["fas", "cash-register"],
          iconColor: "text-success",
        },
        DRAWER: {
          style: "border-info",
          icon: ["fas", "cash-register"],
          iconColor: "text-warning",
        },
        CASHOUT: {
          style: "border-success",
          icon: ["fal", "cash-register"],
          iconColor: "text-success",
        },
      },
      data: [],
    };
  },
  computed: {
    ...mapState(["api"]),
    emptyData() {
      return !(Object.values(this.data).length > 0);
    },
  },
  methods: {
    syncronise() {
      this.loading = true;
      this.axios
        .post("get/dashboard/logEntries/")
        .then((res) => {
          if (res.data.success) {
            this.data = Object.values(res.data.data);
          }
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    getTime(time) {
      return moment.unix(time).calendar(null, {
        sameDay: "[" + this.$t("generic.lang_todayAt") + "] HH:mm",
        lastDay: "[" + this.$t("generic.lang_yesterdayAt") + "] HH:mm",
        lastWeek: "[last] dddd [at] HH:mm",
      });
    },
    isSupported(item) {
      return this.events[item.eventType] !== {};
    },
    itemNumber(item) {
      return JSON.parse(item.eventLog).qtyItems === 1
        ? " " + this.$t("erp.lang_item")
        : " " + this.$t("erp.lang_items");
    },
    async getSalesData() {
      this.loading = true;
      this.showTopChart = false;

      var config = {
        method: "post",
        url: `${ENDPOINTS.DATATABLES.ANALYTICS.SALESPERHOUR}`,
        headers: {
          "Content-Type": "application/json",
        },
        params: this.params,
      };

      await this.axios(config)
        .then((res) => {
          if (res.status === 200) {
            var data = res.data.aaData;

            if ((Array.isArray(data) && !data.length) || data == null) {
              Events.$emit("showSnackbar", {
                message: this.$t("generic.lang_noDataToDisplay"),
                color: "warning",
              });
              return;
            }

            for (let i = 2; i < data[0].length; i++) {
              this.series[0].data.push(data[0][i]);
            }

            this.showTopChart = true;

            Events.$emit("showSnackbar", {
              message: this.$t("generic.lang_dataLoading"),
              color: "success",
            });
          } else {
            Events.$emit("showSnackbar", {
              message: res.data.msg || this.$t("generic.lang_errorOccurred"),
              color: "error",
            });
          }
        })
        .catch((err) => {
          Events.$emit("showSnackbar", {
            message: err.message,
            color: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },

  async mounted() {
    let self = this;
    this.timer = setInterval(self.syncronise(), 1000 * 60 * 5);

    await this.getSalesData();
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style scoped></style>
