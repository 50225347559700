<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12" sm="6" md="4">
                <v-switch v-model="enableServiceMonitor" :label="$t('generic.lang_serviceMonitor')" hide-details :disabled="loading" :loading="loading"></v-switch>
            </v-col>
            <v-col cols="12" sm="6" md="4">
                <v-switch v-model="enableServiceMonitorOrderBonPrint" :label="$t('generic.lang_printOrderBonInServiceMonitor')" hide-details :disabled="loading" :loading="loading"></v-switch>
            </v-col>
            <v-col cols="12" sm="6" md="4">
                <v-switch v-model="kitchenmon_displayExtraTabNames" :label="$t('generic.lang_displayExtraTabNames')" hide-details :disabled="loading" :loading="loading"></v-switch>
            </v-col>
            <v-col cols="12">
                <v-checkbox v-model="clearKitchenmonitorOrders" :label="$t('settings.lang_clearKitchenmonitorOrdersAfterDailyReportHasBeenPrinted')"></v-checkbox>
            </v-col>
            <v-col cols="6">
                <v-select outlined
                          :items="orderbonPrinters"
                          item-text="name"
                          item-value="id"
                          v-model="serviceMonitorPrinterId"
                          :label="$t('settings.langOrderbonPrinter')"
                ></v-select>
            </v-col>
            <v-col cols="12" align="right">
                <v-btn @click="updateSettings" :loading="loading" :disabled="loading" color="primary">{{ $t("generic.lang_update") }}</v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { ENDPOINTS } from '../../../config';
import { Events } from "@/plugins/events";
import { mapState } from "vuex";

export default {
    name: "KitchenMonitorSettings",
    data(){
        return {
            loading: false,
            enableServiceMonitor: false,
            serviceMonitorPrinterId: false,
            kitchenmon_displayExtraTabNames: false,
            enableServiceMonitorOrderBonPrint: null,
            clearKitchenmonitorOrders: false,
        }
    },
    computed: {
        ...mapState('printer', {
            printers: state => state.printers
        }),
        orderbonPrinters() {
            return this.printers.filter(printer => printer.type === 2)
        },
    },
    methods: {
        getSettings(){
            this.loading = true;
            this.axios.post(ENDPOINTS.SETTINGS.KITCHENMONITORS.SETTINGS.GET).then(res=> {
                if(res.data.success){
                    this.enableServiceMonitor = res.data.enableServiceMonitor;
                    this.enableServiceMonitorOrderBonPrint = res.data.enableServiceMonitorOrderBonPrint;
                    this.serviceMonitorPrinterId = res.data.serviceMonitorPrinterId;
                    this.kitchenmon_displayExtraTabNames = res.data.kitchenmon_displayExtraTabNames;
                    this.clearKitchenmonitorOrders =  res.data.clearKitchenmonitorOrders;
                }else {
                    Events.$emit("showSnackbar", {
                        message: this.$t('generic.lang_errorOccurred'),
                        color: "error"
                    });
                }

            }).catch(err=> {
                Events.$emit("showSnackbar", {
                    message: this.$t('generic.lang_errorOccurred'),
                    color: "error"
                });
            }).finally(()=>{
                this.loading = false;
            })
        },
        updateSettings(){
            this.loading = true;
            this.axios.post(ENDPOINTS.SETTINGS.KITCHENMONITORS.SETTINGS.UPDATE, {
                enableServiceMonitor: this.enableServiceMonitor,
                enableServiceMonitorOrderBonPrint: this.enableServiceMonitorOrderBonPrint,
                serviceMonitorPrinterId: this.serviceMonitorPrinterId,
                kitchenmon_displayExtraTabNames: this.kitchenmon_displayExtraTabNames,
                clearKitchenmonitorOrders: this.clearKitchenmonitorOrders,
            }).then(res=> {
                if(res.data.success){
                    Events.$emit("showSnackbar", {
                        message: this.$t('generic.lang_ActionSuccessful'),
                        color: "success"
                    });
                }else {
                    Events.$emit("showSnackbar", {
                    message: this.$t('generic.lang_errorOccurred'),
                    color: "error"
                });
                }

            }).catch(err=> {
                Events.$emit("showSnackbar", {
                    message: this.$t('generic.lang_errorOccurred'),
                    color: "error"
                });
            }).finally(()=>{
                this.loading = false;
            })
        },
    },
    mounted(){
        this.getSettings();
    }

}
</script>