<template>
  <div
    class="card mb-3 main-card"
    :style="this.$vuetify.theme.dark ? 'background-color: #424242;' : ''"
  >
    <div
      :class="[this.$vuetify.theme.dark ? 'dark-bg' : '']"
      class="card-header-tab card-header"
    >
      <div
        class="card-header-title font-size-lg text-capitalize font-weight-normal"
      >
        <font-awesome-icon
          :icon="['fal', 'chart-bar']"
          class="kassen pr-2 text-info"
          size="2x"
        />
        {{ $t("settings.lang_numberOfProcessedOrdersPerHour") }}
      </div>
    </div>

    <div class="card-body pa-0">
      <div v-if="loading" class="text-center">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>

      <apexchart
        v-else
        height="400"
        type="bar"
        :options="chartOptions"
        :series="series"
      ></apexchart>
    </div>
  </div>
</template>

<script>
import { ENDPOINTS } from "@/config";
import { Events } from "@/plugins/events";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faChartBar } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
library.add(faChartBar);
import VueApexCharts from "vue-apexcharts";

export default {
  name: "SalesChartNumberOfProcessedTotalOrders",

  components: {
    FontAwesomeIcon,
    apexchart: VueApexCharts,
  },

  data() {
    return {
      ENDPOINTS,
      loading: true,
      data: [],
      series: [
        {
          name: this.$t("generic.lang_orders"),
          data: [
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0,
          ],
        },
      ],
      chartOptions: {
        chart: {
          stacked: true,
        },
        theme: {
          mode: this.$vuetify.theme.dark ? "dark" : "light",
        },
        stroke: {
          curve: "smooth",
          width: [0, 4],
        },
        dataLabels: {
          enabled: false,
        },
        labels: [
          "00:00",
          "01:00",
          "02:00",
          "03:00",
          "04:00",
          "05:00",
          "06:00",
          "07:00",
          "08:00",
          "09:00",
          "10:00",
          "11:00",
          "12:00",
          "13:00",
          "14:00",
          "15:00",
          "16:00",
          "17:00",
          "18:00",
          "19:00",
          "20:00",
          "21:00",
          "22:00",
          "23:00",
        ],
        yaxis: [
          {
            title: {
              text: this.$t("generic.lang_orders"),
            },
          },
        ],
        xaxis: {
          title: {
            text: this.$t("generic.lang_hours"),
          },
        },
      },
    };
  },

  mounted() {
    this.getSalesData();
  },

  methods: {
    async getSalesData() {
      this.loading = true;

      this.axios
        .get(ENDPOINTS.SETTINGS.KITCHENMONITORS.DASHBOARD.TOTAL_ORDERS)
        .then((res) => {
          if (res.status === 200) {
            var data = res.data.data.orderChartToday;

            if (Object.values(data).length === 0) {
              Events.$emit("showSnackbar", {
                message: this.$t("generic.lang_noDataToDisplay"),
                color: "warning",
              });
              return;
            }

            for (const key in data) {
              this.series[0].data[key] = data[key];
            }

            Events.$emit("showSnackbar", {
              message: this.$t("generic.lang_dataLoading"),
              color: "success",
            });
          } else {
            Events.$emit("showSnackbar", {
              message: res.data.msg || this.$t("generic.lang_errorOccurred"),
              color: "error",
            });
          }
        })
        .catch((err) => {
          Events.$emit("showSnackbar", {
            message: err.message,
            color: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style>
.apexcharts-toolbar {
  z-index: 2 !important;
}
</style>
